.skills{
    display: block;
    align-items: center;
    justify-content: center;
    padding:30px;
    min-height: fit-content;
    background-color: #fff;
    position: relative;
    padding:20px;
    font-size: 30px;
    font-family: 'Raleway';
    color:#5a5a5a;
    margin-top: 50px;
    overflow: hidden;
    backdrop-filter: drop-shadow(2px 4px 6px black);
    z-index:2;

    h2{
        text-align: center;
    }

    .table{
        display: flex;
        flex-direction: column;
        gap: 10px;
        h3{
            grid-area: category;
        }
        p{
            grid-area: description;
            text-transform: uppercase;
            background-image: linear-gradient(269deg, #35126a 0%, #35126a 29%, #44107a 67%, #fc1361 100%);
            background-size: auto auto;
            background-clip: border-box;
            background-size: 30% auto;
            color: #fff;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: 800;
            display: inline-block;

            // grid-area: description;
            // text-transform: uppercase;
            // background-image: linear-gradient(388deg, #ff1361 0%, #44107a 29%, #44107a 67%, #231557 100%);
            // background-size: auto auto;
            // background-clip: border-box;
            // background-size: 100% auto;
            // color: #fff;
            // // background-clip: text;
            // text-fill-color: transparent;
            // // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // animation: textclip 3s linear infinite; 
            // display: inline-block;
            // font-family: 'Roboto';
            // font-weight: 800;
        }
        div{
            font-size: 20px;
            display: grid;
            grid-template-columns: 20% 70%;
  grid-template-rows: auto;
  grid-template-areas: "category description"
             
        }

        div:nth-child(1){
            p{
                animation: textclip 10s linear infinite;
            }
        }
        div:nth-child(2){
            p{
                animation: textclip 20s linear infinite;
            }
        }
        div:nth-child(3){
            p{
                animation: textclip 30s linear infinite;
            }
        }
        div:nth-child(4){
            p{
                animation: textclip 40s linear infinite;
            }
        }
        
        @keyframes textclip {
            to {
              background-position: 200% 50%;
            }
          }
    }
/* For Tablet View */
@media screen and (max-width: 767px)
{
    .table{

            div{
                display: flex;
                flex-direction: column;
                h3{
                    text-align: center;
                    margin: 0;
                }
                p{
                    margin: 10px 0 20px 0;
                    text-align: center;
                }
            }
        
    }

}

}





