.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    background-color: #fff;
    height: 100px;
    width: 100px;
    border-radius: 70px;
    padding: 10px;
    z-index: 1;
    border:2px solid #ff8b00;
    // animation: shadow 2s ease-in-out infinite;
 
    @keyframes shadow{

        0%{
            filter: drop-shadow(0px 0px 10px #ffffff);
        }
        25%{
            filter: drop-shadow(8px 5px 10px #ffffff);

        }
        50%{
            filter: drop-shadow(5px 8px 10px #ffffff);
                }
        100%{
            filter: drop-shadow(-8px -5px 10px #ffffff);

        }
    }

    
    
    img{
        width:70px;
        height:auto;
    }
    
    span{
        font-family: 'Belanosima';
        font-size: 22px;
        color:#000;
    }
}

.logo::before
{
    position: absolute;
    content: '';
    width: 120px;
    height: 120px;
    z-index: 0;
    border-radius: 50%;
    transform: translate(0%,0%);
    animation: rotate 50s linear infinite;
}
.red
{
    // background-color: #CA0B00;
}
.red::before
{
    border: 5px dashed #ff8b00;
}
@keyframes rotate
{
    from
    {
    transform:rotate(360deg);
    }
    to
    {
    transform:rotate(-360deg);
    }
}

