.contact{
    display: block;
    align-items: center;
    justify-content: center;
    padding:30px;
    min-height: fit-content;
    background-color: #fff;
    position: relative;
    padding:20px;
    font-size: 30px;
    font-family: 'Raleway';
    color:#5a5a5a;
    margin-top: 30px;
    overflow: hidden;
    backdrop-filter: drop-shadow(2px 4px 6px black);
    z-index:2;
    text-align: center;
    h2{
        text-align: center;
    }
    a{
        font-size: 26px;
    }
}