#tsparticles {
    position: absolute;
    // right: 0;
    // right: 0;
    // bottom: 0;
    // left: 0;

    z-index: 3;
    width: 100%;
    height:100%;
  }