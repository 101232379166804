@import "~react-image-gallery/styles/scss/image-gallery.scss";

.portfolio{
    padding-bottom:25px;
}
h5{
    text-align: left;
    font-family: 'Roboto';
    margin-bottom: 20px;
    color: #e76516;
    font-weight: 500;
    margin-top: 55px;
}
.section{
    border-top:1px solid #ccc;
    display: flex;
    flex-direction: row;
    height: fit-content;
    overflow: hidden;
    
    .thumbnail{
        min-width:200px;
        width:20%;
        display: flex;
        flex-direction: column;
        gap:20px;
        padding:20px;

        img{
            width: auto;
            height: 80%;
        }

        
    }
    .description{
        width:70%;
        margin-left: 5%;
        font-size: 20px;
        padding: 20px;
        .buttons{
            display: flex;
            flex-direction: row;
            gap:20px;
            width: 300px;
            margin-top: 30px;

            .view-images{
                background-color: #6219a9;
                color: #fff;
                border: 1px solid #6d2dab;
                border-radius: 10px;
                /* height: 30px; */
                padding: 10px;
                width: 50%;
                font-size: 14px;
                font-family: 'Roboto';
                text-transform: uppercase;
                cursor: pointer;
            }
            .view-site{
                background-color: #484848;
                color: #fff;
                border: 1px solid #919191;
                border-radius: 10px;
                /* height: 30px; */
                padding: 10px;
                width: 50%;
                font-size: 14px;
                font-family: 'Roboto';
                text-transform: uppercase;
                cursor: pointer;
            }
            .view-images:hover, .view-site:hover{
                opacity: 0.8;
            }
        }
    }

}

.image-gallery {
    width: 100%;
    aspect-ratio : 1 / 1;
}

.image-gallery-content{
    height: 100%;
}
.image-gallery-slide-wrapper{
    height: 100%;
}

.image-gallery-swipe{
    // height:100%;
    width:80%;
    height:80%;
    margin-left:10%;
}

.image-gallery-slides{
    height:100%;
    background-color: #262626;
    border-radius: 190px;
}

.image-gallery-slide{
    height: 100%;
    background-color: #262626;
}

.image-gallery-content .image-gallery-slide .image-gallery-image{
    height: 100%;
    width: inherit;
}
.image-gallery-slide img {
    width: 100%;
    height: auto;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 40px;
    width: 40px;
    background-color: #1a1a1a;
    border: 2px solid #202020;
    border-radius: 41px;
}
.image-gallery-icon {
    color: #ff8400;
}

.image-gallery-icon:hover{
    color:#fff;
}

.image-gallery-left-nav, .image-gallery-right-nav {
    padding: 0px 7px;
    // top: 104%;
    top:unset;
    bottom:-25px;
    // transform: translateY(-80%);
    filter: unset;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}

.image-gallery-fullscreen-button {
    right: 0;
    top: 0;
    padding: 0px;
    color: #ff8400;
    bottom: unset;
    filter: unset;
}

.image-gallery-fullscreen-button:hover{
    color:#ccc;
}

.image-gallery-content.fullscreen{
    .image-gallery-slides{
        border-radius: unset;
    }
    .image-gallery-left-nav, .image-gallery-right-nav {
        padding: 0px 7px;
        top: unset;
        bottom:0;
        
        transform: translateY(-80%);
        filter: unset;
    }
    .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
        border:1px solid #fff;
    }
    .image-gallery-fullscreen-button{
        right: 10px;
    top: 10px;
    padding: 5px;
    border-radius: 10px;
    background-color: #fff;
    width: 38px;
    height: 38px;
    }
}




/* For Tablet View */
@media screen and (max-width: 767px)
{
    .section {
        flex-direction: column;
        height: fit-content;
        justify-content: center;
        align-items: center;
        .thumbnail{
            min-width:200px;
        }
        .description{
            margin-left: 0;
            width:100%;
        }
        .image-gallery-left-nav, .image-gallery-right-nav {
            // top:111%;
        }   
        .buttons{
            min-width:100%;
        }
    }   
}
 
/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
    and (orientation: portrait) {
    
}
 
/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
    and (orientation: landscape) {
    
}
 
/* For Mobile Phones Portrait or Landscape View */
@media screen
    and (max-device-width: 640px) {
    
}
 
/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
    and (-webkit-min-device-pixel-ratio: 2) {
    
}
 
/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px)
    and (device-width: 320px)
    and (-webkit-min-device-pixel-ratio: 2) {
    
}
 
/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px)
    and (min-device-width: 375px)
    and (-webkit-min-device-pixel-ratio: 3) {
    
};