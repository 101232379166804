.landscape{
   
}
.background{
    // position: absolute;
    perspective: 10px;
    // border:1px solid red;
    height:100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    background-image: url(../../../public/gradient.png);
    background-repeat: repeat repeat;

    .sunset2{
        // top:167vh;
        z-index:2;
        position: relative;
        // max-width:100%;
        margin-top:-21px;
        height: 52vh;
        // width:100%;
//         @media screen and (max-width: 767px)
// {
//     right: calc(1534px - 128vw);
//     width: unset;
//     height:52vh;
// }
        // transform: translateZ(-20px) scale(3);
    }
    .sunset1{
        position: relative;
        z-index: 0;
        height:50vh;
        // transform: translateZ(-20px) scale(3);
        // max-width:100%;
        // width:100%;
        
        // @media screen and (max-width: 767px)
        // {
        //     right: calc(1534px - 128vw);
        //     height:50vh;
        //     width: unset;
        // }
    }
    // .mountain{
    //     z-index:1;
    //     top:69vh;
    //     position: absolute;
    //     // transform: translateZ(20px);
    // }
    .sunset2{
        // display: none;
    }
}
// .background-video{
//     width:100vw;
//     height:100vh;
//     max-height:100vh;
//     object-fit: cover;
//     position:fixed;
//     z-index: 0;
//     transform: translateZ(-40px) scale(5);
// }

// .boat{
//     // transform: translateX(700px) translateY(412px) translateZ(-10px) scale(1);
//     z-index: 2;
//     position: fixed;
//     width:24vw;
//     top: 100vh;
//     right: -20vW;
//     // transform: translateZ(-30px) scale(0.5);
//     animation: floating 20s linear infinite;
    
//     // object-fit: cover;
//     /* For Tablet View */
// @media screen and (max-width: 767px)
// {
//     top:100vh;
//     right:0vw;
//     animation: floating 30s linear infinite;
    
// }
// }

@keyframes floating {
    0%{
        transform: rotate(0deg) translateY(-20px) translateX(-20px) translateZ(0px) scale(2);
    }
    15%{
        transform: rotate(-2deg) translateY(-15px) translateX(-15px) translateZ(-10px) scale(1.5);
    }
    25%{
        transform: rotate(2deg) translateY(-10px) translateX(0px) translateZ(-10px) scale(1.4);
    }
    50%{
        transform: rotate(0deg) translateY(-100px) translateX(-100px) translateZ(-30px) scale(1.3);
    }
    75%{
        transform: rotate(-10deg) translateY(-300px) translateX(-300px) translateZ(-50px) scale(1.2);
    }
    100%{
        transform: rotate(-10deg) translateY(-600px) translateX(-600px) translateZ(-80px) scale(0.1);
    }

    
}


.logo-panel{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 100vw;
    // height: 100vh;;
    gap:20px;
    z-index: 1;
    position: relative;
    // top:35%;
    bottom: 0;
    transform: translateZ(-10px) scale(1.5);
    // background-color: #fff;
    // @media screen and (max-width: 767px)
    // {
    //     top:26%;
    //     height:40vh;
    // }

    .subtitle{
        // color: #fff;
        font-size: 38px;
        color: #fff;
        text-shadow: 3px 0px 5px #000;
        backdrop-filter: drop-shadow(2px 4px 6px black);
        text-transform: uppercase;
        font-weight: 500;
    }
}


.container,
.sea,
.sky,
.sun,
.light,
.bird,
.birdr1,
.birdr,
.fin,
.wave {
  position: absolute;
  z-index: 3;
}

.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  top: 0%;
  left: 50%;
  margin-left: -50%;
//   margin-top: -200px;
}

.sky {
  opacity: .8;
  width: 100%;
  height: 50%;
  top: 0;
//   background: radial-gradient(at 50% 70%, #820, #610, #400, #100);
  z-index: 30;
}



.sun {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  left: 45%;
  z-index: 1;
//   background: linear-gradient(#f1f150, #dd4700);
//   box-shadow: 0 0 40px 6px #f20, 0 0 150px 10px #f33;
  animation: rise 10s ease forwards;
top:50%;
img{
    width: 100%;
    
}

@media screen and (max-width: 767px)
{
    left:30%;
    animation: riseMobile 10s ease forwards;
}
}


@keyframes rise {
  0% {
    top: 72%;
  }
  100% {
    top: 30%;
  }
}

@keyframes riseMobile {
    0% {
      top: 72%;
    }
    100% {
      top: 30%;
    }
  }

.light {
  opacity: .5;
  left: 0%;
  width: 100vw;
  height: 100vh;
//   background: radial-gradient(at 50% 0%, #000, transparent);
background-color: #000;
  z-index: 3;
  opacity: 0.8;
  animation: over 10s ease forwards;
}

@keyframes over {
  0% {
    opacity: 0.8
  }
  ;
  100% {
    opacity: 0
  }
  ;
}

.bird {
  opacity: 1;
  position: absolute;
  top: 260px;
  left: 00px;
  width: 10px;
  height: 8px;
  border: 2px solid black;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform: rotateZ(-75deg);
  animation: move 20s linear infinite, flap .5s linear alternate infinite;
  z-index: 70;
}

.birdr {
  z-index: 70;
  position: absolute;
  top: 260px;
  opacity: 1;
  width: 10px;
  height: 8px;
  border: 2px solid black;
  border-radius: 50%;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  transform: rotateZ(75deg);
  margin: 0px 8px;
  animation: mover 15s linear infinite, flap .5s linear alternate infinite;
}

.bird1 {
  opacity: 1;
  position: absolute;
  top: 180px;
  left: 0px;
  width: 10px;
  height: 8px;
  border: 2px solid black;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform: rotateZ(-90deg);
  animation: move 50s linear infinite 1.5s, flap .5s linear alternate infinite 1.5s;
  z-index: 70;
}

.b2{
    top:150px;
    margin-left:100px;
    animation: move 20s linear infinite 1.5s, flap .5s linear alternate infinite 1.5s;
}

.b3{
    top:220px;
    margin-left:150px;
    animation: move 40s linear infinite 1.5s, flap .5s linear alternate infinite 1.5s;
}

.b4{
    top:170px;
    margin-left:250px;
    animation: move 50s linear infinite 1.5s, flap .5s linear alternate infinite 1.5s;
}

.birdr1 {
  z-index: 70;
  
  opacity: 1;
  position: absolute;
  top: 200px;
  width: 10px;
  height: 8px;
  border: 2px solid black;
  border-radius: 50%;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: transparent;
  transform: rotateZ(90deg);
  margin: 0px 8px;
  animation: mover 25s linear infinite 1.5s, flap .5s linear alternate infinite 1.5s;
}

.fin {
  border: 30px solid black;
  top: 90%;
  left: 100%;
  z-index: 100;
  border-left: 30px solid transparent;
  border-top: 0px solid transparent;
  border-right: 10px solid transparent;
  border-radius: 0px 0px 2px 10px;
  animation: swim 30s linear forwards 10s;
}

.wave {
  margin: 26px 7px;
  border-left: 3px solid transparent;
  border-top: 6px solid rgba(50, 50, 140, .3);
  border-right: 50px solid transparent;
  transform: rotatez(6deg);
  z-index: 60;
}

@keyframes swim {
  0% {
    left: 100%;
  }
  
  100% {
    left: -25%
  }
  ;
}

@keyframes move {
  0% {
    left: 0px;
  }

  50%{
    margin-top:-50px;
  }

  100% {
    left: 100%;
    margin-top:100px;
  }
}

@keyframes mover {
  0% {
    left: -0px;
  }
  50%{
    margin-top:-20px;
  }
  100% {
    left: 100%;
    margin-top:0px;
  }
}

@keyframes flap {
  0% {
    border-radius: 90%;
  }
  100% {
    border-radius: 10%;
  }
}

/* For Desktop View */
@media screen and (min-width: 1024px) {
    
}

/*ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
and (orientation: portrait) {
  /* CSS styles specific to iPad portrait mode */
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
and (orientation: landscape) {
 /* CSS styles specific to iPad landscape mode */
}

/*ipad air*/
 /* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
 /* CSS styles specific to iPad Air portrait mode */
}
/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) 
and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
 /* CSS styles specific to iPad Air landscape mode */
}


/* For Tablet View */
@media screen and (min-device-width: 768px)
    and (max-device-width: 1024px) {
    
}
 
/* For Mobile Portrait View */
@media screen and (max-width: 1000px)
 {

 .sunset1{
    // right: calc(1534px - 165vw);
        height:50vh;
        width: unset;
        // object-fit: none;
        // object-position: calc(540px / 50vH) -80px ;
 }  
 .sunset2{
    // right: calc(1534px - 165vw);
        height:54vh;
        width: unset;
        // object-fit: none;
            // object-position: -1150px -10px ;
 }
 .sun{
  left:20%;
 }
}

@media screen and (max-width: 500px)
 {

 .sunset1{
    // right: calc(1534px - 165vw);
        height:50vh;
        width: unset;
        // object-fit: none;
        // object-position: calc(540px / 50vH) -80px ;
 }  
 .sunset2{
    // right: calc(1534px - 165vw);
        height:54vh;
        width: unset;
        // object-fit: none;
            // object-position: -1150px -10px ;
 }
 .sun{
  left:10%;
 }
}
 
/* For Mobile Landscape View */
@media screen and (max-device-width: 1000px)
    and (orientation: landscape) {
        .sunset1{
            // right: calc(1534px - 165vw);
                height:50vh;
                width: unset;
         }  
         .sunset2{
            // right: calc(1534px - 165vw);
                height:54vh;
                width: unset;
         } 
}
 
/* For Mobile Phones Portrait or Landscape View */
// @media (min-device-width:481px)
//     and (max-device-width: 640px) {
//         .sunset1{
//             right: 0px;
//                 height:50vh;
//                 width: unset;
//                 // object-fit: none;
//                 //     object-position: -1050px -80px ;
//          }  
//          .sunset2{
//             right: 0;
//                 height:52vh;
//                 width: unset;
//                 // object-fit: none;
//                 //     object-position: -1050px -10px ;
//          } 
// }
 
/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
    and (-webkit-min-device-pixel-ratio: 2) {
    
}
 
/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px)
    and (device-width: 320px)
    and (-webkit-min-device-pixel-ratio: 2) {
    
}
 
/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px)
    and (min-device-width: 375px)
    and (-webkit-min-device-pixel-ratio: 3) {
    
};