.about{
    // height:400px;
    display: block;
    align-items: center;
    justify-content: center;
    z-index:2;
    background-color: #fff;
    position: relative;
    padding:20px;
    font-size: 30px;
    font-family: 'Raleway';
    color:#5a5a5a;
    backdrop-filter: drop-shadow(2px 4px 6px black);
    min-height: fit-content;

    h2{
        font-family: 'Raleway';
        text-align: center;
        font-size:35px;
        color:#1e57db;
    }
    
    
    P:nth-child(2){
            text-align: right;
            margin-top:-20px;
        }
    p:nth-child(1){
            font-style: italic;
            font-size: 22px;
            color: #000;
        }
    
}
/* For Tablet View */
@media screen and (max-width: 767px)
{
    .about{
        min-height: fit-content;
    }
}

